import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { filter } from 'rxjs/operators';
import splash$ from '@b2c/core/app/service/rx/splash$.js';

(function () {
  'use strict';

  const directive = { name: 'bnrAnimation' };

  controller.$inject = ['splash$', '$document'];

  function controller(_splash$, $document) {
    function link(scope, element, attrs) {
      gsap.config({ nullTargetWarn: false, trialWarn: false });
      gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

      const content = element[0].querySelector('.entrance-bnr__content');
      const img = element[0].querySelector('.entrance-bnr__img');
      const card = element[0].querySelector('.entrance-bnr-card');

      const scrollMove = () => {
        const triggerParams = {
          trigger: '.entrance-bnr-wrapper',
          start: 'bottom 700',
          end: '+=500',
          scrub: true,
        };

        gsap
          .timeline({ scrollTrigger: triggerParams })
          .to(content, { y: '-20%', opacity: '0', duration: 1, stagger: 0 });

        gsap.timeline({ scrollTrigger: triggerParams }).to(img, { y: '-20%', opacity: '0', duration: 1, stagger: 0 });

        gsap.timeline({ scrollTrigger: triggerParams }).to(card, { y: '-20%', opacity: '0', duration: 1, stagger: 0 });
      };

      const bnrStart = gsap
        .timeline({ paused: true })
        .from(content, { x: '-60px', opacity: 0, duration: 0.2, stagger: 0.2 })
        .from(img, { x: '100%', y: '-62%', opacity: 0, duration: 0.3, stagger: '-=0' })
        .from(card, { scale: '0.6', opacity: '0', onComplete: scrollMove, duration: 0.3, stagger: '-=0' });

      if ($document[0].body.classList.contains('loaded')) {
        bnrStart.play();
      } else {
        _splash$.pipe(filter((m) => m === 'loaded')).subscribe((m) => {
          bnrStart.play();
        });
      }
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
