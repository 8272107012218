import '../../service/domain/collection.js';
import '../../service/domain/state.js';

(function () {
  'use strict';
  controller.$inject = ['$state', '$q', 'collection', 'state'];

  function controller($state, $q, _collection, _state) {
    const defer = $q.defer();

    function isValidRoute(collection) {
      return collection ? defer.resolve() : defer.reject(_state.goto('notFound'));
    }

    function collection() {
      const { category, collection } = $state.transition.params() || {};
      return _collection
        .collectionHall()
        .then((answer) => {
          const collectionByAlis = answer.result.toObject('alias');
          const currentCollection = collectionByAlis[collection];
          if (category) {
            return isValidRoute(currentCollection.categories.toObject('name')[category]);
          }
          return isValidRoute(currentCollection);
        })
        .catch(() => {
          _state.goto('notFound');
        });
    }

    return { collection };
  }

  app.factory('casinoResolver', controller);
})();
