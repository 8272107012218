import build from '../../../gulp/date-build.js';

(function () {
  'use strict';

  const isProduction = $_CONFIG.production || process.env.NODE_ENV === 'production';

  const config = {
    apiUrl: isProduction && !$_CONFIG.mobileApp ? '' : $_CONFIG.domain,
    cdn: $_CONFIG.cdn || '/joxi',
    domain: $_CONFIG.domain || '',
    siteDomain: window.location.origin || '',
    mobileSiteDomain: $_CONFIG.mobileSiteDomain,
    siteName: $_CONFIG.siteName || '',
    build: build,
    buildVersion: $_VERSION.version,
    mobile: $_CONFIG.mobile,
    locale: $_CONFIG.locale || '',
    languages: $_CONFIG.language || ['en'],
    liveAgent: {},
    chat: 'liveAgent',
    freshChat: $_CONFIG.freshChat || {},
    preChatFormConfig: $_CONFIG.preChatFormConfig,
    currentLang: $_CONFIG.currentLang,
    alternates: $_DICT.alternates || {},
    sessionStatus: null,
    userActivated: null,
    device: $_CONFIG.device,
    browser: $_CONFIG.browser,
    apiVersion: $_CONFIG.apiVersion || 'v2',
    currency: $_CONFIG.currency || window.$_DICT.currency || [{ key: 'EUR', name: '', icon: '€' }], // todo: for sweden .filter( o => o.key === 'SEK' && (new Date()).getFullYear() === 2019 ? false : true ),
    mobileApp: $_CONFIG.mobileApp || false,
    mobileConfig: $_CONFIG.mobileConfig || {},
    production: isProduction,
    product: $_CONFIG.product || 'casino',
    mid: $_CONFIG.mid || '',
    aliases: $_CONFIG.aliases || window.$_ALIAS || {},
    links: $_CONFIG.links || {},
    licenseId: $_CONFIG.licenseId || '',
    redirectToNotFound: $_CONFIG.redirectToNotFound || false, // true: go to 404 if seo data not found
    regily: $_CONFIG.regily || {},
    sentry: $_CONFIG.sentry || {},
    jurisdiction: $_CONFIG.jurisdiction || 'curacao',
    jurisdictions: $_CONFIG.jurisdictions || {
      de: 'germany',
      sw: 'sweden',
    },
    project: $_CONFIG.project || '',
    projectType: $_CONFIG.projectType,
    state: {
      isEntercash: false,
    },
    sport:
      {
        fixedBottom: $_CONFIG.sportFixedBottom || 55,
        fixedTop: $_CONFIG.sportFixedTop,
        banners: $_CONFIG.banners || {},
        callbackUrl: $_CONFIG.sportBannerCallbackUrl || $_CONFIG.mobileSiteDomain,
        initializationParams: $_CONFIG.sportInitializationParams || {},
        insufficientbalancecallback: $_CONFIG.sportInsufficientBalanceCallback,
        hasSport: $_CONFIG.sport,
      } || false,
    sportVersion: $_CONFIG.sportVersion || 1,
    altenar: {
      widget: {
        integration: $_CONFIG.sportId || '',
      },
    },
    pwa: false, // Змінюється директивою linda-pwa у випадку коли сайт запущено в режимі progressive web application
    ios: Boolean(navigator.userAgent.match(/ipad|iphone/i)),
    slider: typeof $_CONFIG.slider === 'object' ? $_CONFIG.slider : {},
    googleAnalytics: $_CONFIG.googleAnalytics && $_CONFIG.googleAnalytics.appId ? $_CONFIG.googleAnalytics : {},
    hotjar: $_CONFIG.hotjar ? $_CONFIG.hotjar : {},
    feature: $_CONFIG.feature || {},
    isPayNPlayRegistrationAvailable: $_CONFIG.isPayNPlayRegistrationAvailable || true,
    sportBanners: $_CONFIG.sportBanners || null,
    ignoreErrorCodes: $_CONFIG.ignoreErrorCodes || [], // Disable notifications for codes in ignoreErrorCodes array
    pgw: $_CONFIG.pgw,
    insertCdnScripts: true,
    freshDeskId: '',
    scripts: [],
    gsap3Plugins: [],
    wsScript: 'socket/socket.io.js',
    customScripts: [],
    useSvgSprite: $_CONFIG.useSvgSprite || true,
    paynplay: $_CONFIG.paynplay || undefined,
    cdnOnfidoFiles: {
      js: `https://unpkg.com/onfido-sdk-ui@12.2.0/dist/onfido.min.js`,
      style: `https://unpkg.com/onfido-sdk-ui@12.2.0/dist/style.css`
    }
  };

  let _system_info = {};

  function getLACreds(type) {
    const key = $_CONFIG.liveAgent[config.currentLang] ? config.currentLang : 'default';
    return $_CONFIG.liveAgent[key][type];
  }

  Object.defineProperty(config, 'system_info', {
    set(value) {
      _system_info = value;
    },
    get() {
      return Object.assign({}, { platform_version: '2' }, _system_info);
    },
  });

  Object.defineProperty(config.liveAgent, 'sid', {
    set(value) {},
    get() {
      return getLACreds('sid');
    },
  });

  Object.defineProperty(config.liveAgent, 'bid', {
    set(value) {},
    get() {
      return getLACreds('bid');
    },
  });

  const cdnFeature = window.$_CDN?.feature || {};
  let localStorageFeature = null;
  try {
    localStorageFeature = JSON.parse(window.localStorage.getItem('feature-flags'));
  } catch {
    /* empty */
  }

  config.feature = localStorageFeature ? localStorageFeature : cdnFeature;

  const apiUrl = window.localStorage.getItem('api-url') || '';
  if (apiUrl.length) {
    config.apiUrl = apiUrl;
  }

  config.router = $_CONFIG.router.length ? $_CONFIG.router : window.$_ROUTER || [];

  app.constant('config', Object.assign(config, $_CONFIG));
})();
