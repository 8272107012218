import { consume } from '@lit/context';
import { SlDialog } from '@shoelace-style/shoelace';
import {
  html,
  css,
  LitElement,
  CSSResult,
  TemplateResult,
  PropertyValues,
} from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';

import './funid-auth-qr';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  Observable,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { io, Socket } from 'socket.io-client';
import {
  FunIdAuthDataModel,
  FunidAuthError,
  FunidAuthEvent,
  FunidViewType,
} from '../model/funid-auth-model';
import { funIdConfigContext } from '../services/context/funid-config-context';
import { FunIdConfigService } from '../services/funid-config-service';
import { FunIdAuthLogger } from '../services/funid-auth-logger';
import { funIdAuthLoggerContext } from '../services/context/funid-auth-logger-context';
import { EventsMap } from '@socket.io/component-emitter';

@customElement('funid-auth-modal')
export class FunidAuth extends LitElement {
  public static styles: CSSResult = css`
    .dialog {
      --width: 480px;

      position: relative;
      z-index: var(--fid-auth-dialog-z-index);
      font-family: 'Montserrat', sans-serif;
      font-optical-sizing: auto;
      font-weight: 600;
      font-style: normal;
    }

    [langcode='ar'] {
      direction: rtl;
    }

    .dialog::part(panel) {
      border-radius: 32px;
      overflow: hidden;
    }

    .dialog::part(body) {
      color: var(--text-main);
      padding: 0;
    }

    button,
    a {
      cursor: pointer;
    }

    .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 32px;
    }

    .description {
      font-size: 14px;
      line-height: 16px;
    }

    .qr-code {
      display: block;
      width: var(--fid-auth-dialog-qr-width);
      margin: var(--fid-auth-dialog-qr-margin);
    }

    .mobile-only {
      @media screen and (min-width: 1280px) {
        display: none !important;
      }
    }

    .steps {
      display: flex;
      justify-content: center;
      gap: 6px;
      border-radius: 12px;
      padding: 8px;
      background-color: #f4f4f9;
      margin-bottom: 12px;

      @media screen and (min-width: 1280px) {
        background: 0;
      }
    }

    .steps--item {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 8px;
      border-radius: 12px;
      background: #fff;
      align-items: center;
      flex: 1 1 0;
    }

    @media screen and (min-width: 1280px) {
      .steps--item:not(:first-child):not(:last-child) {
        position: relative;
      }

      .steps--item:not(:first-child):not(:last-child)::before,
      .steps--item:not(:first-child):not(:last-child)::after {
        content: '';
        display: block;
        position: absolute;
        width: 36px;
        height: 1px;
        background-color: #e2e1f6;
        top: 36px;
      }

      .steps--item:not(:first-child):not(:last-child)::before {
        right: 88%;
      }

      .steps--item:not(:first-child):not(:last-child)::after {
        left: 88%;
      }
    }

    .steps--icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      width: 52px;
      height: 52px;
      background-color: #f4f4f9;
      border-radius: 8px;

      @media screen and (min-width: 1280px) {
        border: 1px solid #e5e0fc;
        background: 0;
        border-radius: 12px;
      }
    }

    .steps--icon {
      display: block;
      width: 28px;
      height: 28px;
    }

    .steps-item--text {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      margin: 0;
      color: var(--text-secondary);

      @media screen and (min-width: 1280px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .downloads-text {
      font-size: 14px;
      line-height: 16px;
    }

    .downloads {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .downloads-item {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      border: 0;
      padding: 12px 30px;
      gap: 8px;
      background-color: var(--fid-auth-get-app-button-background-color);
      text-decoration: none;
    }

    .downloads-item--icon {
      display: block;
      width: 32px;
      height: 32px;
    }

    .downloads-item--text {
      color: var(--fid-auth-get-app-button-text-color);
      font-size: 16px;
      line-height: 20px;
    }

    .show-more {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      background: 0;
      border: 0;
      text-align: center;
      color: var(--fid-auth-show-more-button-color);
      margin: 0 auto;
      gap: 8px;
    }

    .show-more--icon circle {
      stroke: red;
    }

    .show-less {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 20px 4px 4px;
      padding-inline: 4px 20px;
      gap: 12px;
      border-radius: 100px;
      border: 2px solid #d7d5f8;
      background: 0;
      align-self: center;
    }

    .show-less--text {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    .show-less--image {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #3f38dd; // TODO: image from cms doesn't fit
    }

    .dialog-image {
      display: block;
      height: 120px;
      width: calc(100% + 40px);
      margin: 0 -20px;
      object-fit: cover;
      object-position: left;
    }

    .dialog-image.lang-ar {
      transform: scale(-1, 1);
    }

    .main-state {
      display: block;
      text-align: center;
      padding: 0 20px 40px;
    }

    .details-state {
      background-color: var(--fid-auth-details-state-background-color);
      padding: 20px 20px 26px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .details--heading {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
    }

    .details--logo {
      display: block;
      width: 72px;
      height: 72px;
    }

    .details--title {
      font-weight: 800;
      font-style: italic;
      font-size: 24px;
      line-height: 30px;
      color: var(--fid-auth-details-title-color);
    }

    .details-body {
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      min-height: 200px;
      max-height: calc(100vh - 368px);
    }

    .details-body--content {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .guide {
      background-color: var(--fid-auth-guide-background-color);
      border-radius: 16px;
      padding: 20px 20px 0;
      overflow: hidden;
    }

    .guide--title,
    .guide--description {
      color: var(--text-main);
    }

    .guide--title {
      font-weight: 700;
      font-size: 18px;
      line-height: 36px;
      margin-bottom: 12px;
    }

    .guide--description {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .guide--media {
      display: block;
      width: calc(100% + 40px);
      height: 220px;
      margin: 0 -20px;
      object-fit: cover;
    }

    .close-button {
      position: absolute;
      top: 20px;
      z-index: 1;
      inset-inline-end: 20px;
    }

    .close-button::part(base) {
      border: 0;
    }

    .close-button::part(label) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .container {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
    }
  `;

  public render(): TemplateResult {
    return html`
      <sl-dialog
        no-header="no-header"
        label="Dialog"
        class="dialog"
        langcode="${this.langCode}"
      >
        <div class="container">
          <sl-button
            class="close-button"
            @click=${this.close}
            variant="default"
            size="medium"
            circle
          >
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.1674 14.5961C13.7532 15.1819 14.7029 15.1819 15.2887 14.5961C15.8745 14.0103 15.8745 13.0605 15.2887 12.4748L12.813 9.99903L15.2877 7.52427C15.8735 6.93848 15.8735 5.98873 15.2877 5.40295C14.7019 4.81716 13.7522 4.81716 13.1664 5.40295L10.6916 7.87771L8.21762 5.4037C7.63184 4.81791 6.68209 4.81791 6.0963 5.4037C5.51052 5.98948 5.51052 6.93923 6.0963 7.52502L8.57032 9.99903L6.09533 12.474C5.50955 13.0598 5.50955 14.0095 6.09533 14.5953C6.68112 15.1811 7.63087 15.1811 8.21665 14.5953L10.6916 12.1203L13.1674 14.5961Z"
                fill="#7E7BB4"
              />
            </svg>
          </sl-button>

          ${
            this.viewType === FunidViewType.QrCode
              ? html`
                <div class="main-state">
                  <img
                    class="dialog-image lang-${this.langCode}"
                    src="${this.withCdnUrl(this.data.background.url)}"
                    alt="${this.data.background.alt}"
                  />

                  <p class="title">${this.data.title}</p>

                  <p class="description">${this.data.description}</p>

                  <funid-auth-qr
                    class="qr-code"
                    .url="${this.url}"
                    .description="${this.data.description}"
                  ></funid-auth-qr>

                  <p class="downloads-text mobile-only">${this.data.text}</p>

                  <div class="steps">
                    ${this.data.steps.items.map(
                      item => html`
                        <div class="steps--item">
                          <div class="steps--icon-wrapper">
                            <img
                              class="steps--icon"
                              src="${this.withCdnUrl(item.icon.url)}"
                              alt="${item.text}"
                            />
                          </div>

                          <p class="steps-item--text">${item.text}</p>
                        </div>
                      `
                    )}
                  </div>

                  <div class="downloads mobile-only">
                    <a
                      href="${this.url}"
                      class="downloads-item"
                    >
                      <img
                        class="downloads-item--icon"
                        src="${this.withCdnUrl(this.data.logo.url)}"
                        alt="${this.data.logo.alt}"
                      >

                      <span class="downloads-item--text">${
                        this.data.text
                      }</span>
                    </a>
                  </div>

                  <button
                    class="show-more"
                    @click=${{
                      handleEvent: () => this.toggleView(FunidViewType.Info),
                    }}
                  >
                    <svg
                      class="show-more--icon"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="1"
                        y="0.5"
                        width="15"
                        height="15"
                        rx="7.5"
                        stroke="#3F38DD"
                      />
                      <path
                        d="M7.61952 12.2958V6.4318C7.61952 6.1918 7.70752 5.9598 7.87552 5.7918C8.04352 5.6238 8.26752 5.5278 8.49952 5.5278C8.73952 5.5278 8.95552 5.6238 9.12352 5.7918C9.29152 5.9598 9.37952 6.1918 9.37952 6.4318V12.2878C9.37952 12.5278 9.28352 12.7598 9.12352 12.9278C8.96352 13.1038 8.73952 13.1998 8.49952 13.1998C8.25952 13.1998 8.04352 13.1038 7.87552 12.9358C7.70752 12.7598 7.61952 12.5358 7.61952 12.2958Z"
                        fill="#3F38DD"
                      />
                      <path
                        d="M7.60352 3.7198C7.60352 3.2158 8.00352 2.7998 8.49952 2.7998C8.99552 2.7998 9.39552 3.2158 9.39552 3.7198C9.39552 4.2318 8.99552 4.6398 8.49952 4.6398C8.00352 4.6478 7.60352 4.2318 7.60352 3.7198Z"
                        fill="#3F38DD"
                      />
                    </svg>

                    <span>${this.data.infoButtonText}</span>
                  </button>
                </div>
                </div>
              `
              : ''
          }

          ${
            this.viewType === FunidViewType.Info
              ? html`
                  <div class="details-state">
                    <div class="details--heading">
                      <img
                        class="details--logo"
                        src="${this.withCdnUrl(this.data.logo.url)}"
                        alt="${this.data.info.title}"
                      />

                      <span class="details--title"
                        >${this.data.info.title}</span
                      >
                    </div>

                    <div class="details-body">
                      <div class="details-body--content">
                        ${this.data.info.guide.map(
                          guide => html`
                            <article class="guide">
                              <h3 class="guide--title">${guide.title}</h3>

                              <p class="guide--description">
                                ${guide.description}
                              </p>

                              ${guide.media.url
                                ? this.videoExtensions.includes(guide.media.ext)
                                  ? html`
                                      <video
                                        class="guide--media"
                                        disableRemotePlayback
                                        muted
                                        autoplay
                                        playsinline
                                        loop
                                      >
                                        <source
                                          type="${guide.media.mime}"
                                          src="${this.withCdnUrl(
                                            guide.media.url
                                          )}"
                                        />
                                      </video>
                                    `
                                  : html`
                                      <img
                                        class="guide--media"
                                        src="${this.withCdnUrl(
                                          guide.media.url
                                        )}"
                                        alt="${guide.media.alt}"
                                      />
                                    `
                                : ''}
                            </article>
                          `
                        )}
                      </div>
                    </div>

                    <button
                      class="show-less"
                      @click=${{
                        handleEvent: () =>
                          this.toggleView(FunidViewType.QrCode),
                      }}
                    >
                      <img
                        class="show-less--image"
                        src="${this.withCdnUrl(this.data.logo.url)}"
                        alt="${this.data.info}"
                      />

                      <span class="show-less--text">
                        ${this.data.info.button}
                      </span>
                    </button>
                  </div>
                `
              : ''
          }
      </sl-dialog>
    `;
  }

  @property({ type: String, reflect: true }) viewType!: FunidViewType;
  @property({ type: String, reflect: true }) open!: boolean;
  @property({ type: String, reflect: true }) url!: boolean;
  @property({ type: String, reflect: true }) langCode!: string;
  @property({ type: Function }) close!: () => void;

  @property({ type: Object })
  public data!: FunIdAuthDataModel;

  @state()
  protected showMore: boolean = false;

  @consume({ context: funIdAuthLoggerContext })
  @property({ attribute: false })
  public logger!: FunIdAuthLogger;

  @consume({ context: funIdConfigContext })
  @property({ attribute: false })
  public config!: FunIdConfigService;

  private socket: Socket<EventsMap, EventsMap> | undefined = undefined;
  private initialViewType?: FunidViewType;

  private readonly videoExtensions: string[] = ['.webm', '.mp4'];

  private dialogSubject$: BehaviorSubject<SlDialog | null> =
    new BehaviorSubject<SlDialog | null>(null);
  private openSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private subscription: Subscription | null = null;
  private dialogCloseSubscription: Subscription | null = null;

  public connectedCallback(): void {
    super.connectedCallback();
    const dialog$: Observable<SlDialog | null> = this.dialogSubject$
      .asObservable()
      .pipe(distinctUntilChanged());
    const open$: Observable<boolean> = this.openSubject$
      .asObservable()
      .pipe(distinctUntilChanged());

    this.subscription = dialog$
      .pipe(
        tap(dialog => {
          if (!dialog) {
            this.unSubscribeWS();
          }
        }),
        filter(Boolean),
        switchMap((dialog: SlDialog) =>
          open$.pipe(map((open: boolean) => ({ dialog, open })))
        )
      )
      .subscribe(({ open, dialog }) => {
        if (open) {
          this.subscribeWS();
          dialog.show();
          this.logger.log('dialog.show()');
        } else {
          dialog.hide();
          this.unSubscribeWS();
          this.logger.log('dialog.hide()');
        }
      });

    this.dialogCloseSubscription = this.dialogSubject$
      .asObservable()
      .pipe(
        filter(Boolean),
        switchMap((dialog: SlDialog) => fromEvent(dialog, 'sl-after-hide'))
      )
      .subscribe(() => {
        if (this.initialViewType !== FunidViewType.Info) {
          this.viewType = FunidViewType.QrCode;
        }
        this.close();
      });
  }

  public firstUpdated(changedProperties: PropertyValues): void {
    super.firstUpdated(changedProperties);

    this.initialViewType = this.viewType;

    this.socket = io(this.config?.getWsApiUrl(), {
      autoConnect: false,
    });
  }

  public disconnectedCallback(): void {
    super.disconnectedCallback();
    this.subscription?.unsubscribe();
    this.dialogCloseSubscription?.unsubscribe();
  }

  public attributeChangedCallback(
    name: string,
    oldValue: string | null,
    value: string | null
  ): void {
    super.attributeChangedCallback(name, oldValue, value);

    if (name !== 'open' || oldValue === value) {
      return;
    }

    this.openSubject$.next(value === 'true');
  }

  public updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);
    const dialog: SlDialog | null =
      this.renderRoot.querySelector<SlDialog>('sl-dialog');

    if (dialog) {
      this.dialogSubject$.next(dialog);
    }
  }

  protected withCdnUrl(url: string): string {
    return `${this.config.getCdnUrl()}${url}`;
  }

  private toggleView(type: FunidViewType): void {
    this.viewType = type;
    this.logger.log(`${this.viewType} view`);
  }

  private async subscribeWS(): Promise<void> {
    if (!this.config.getTransactionId()) {
      return;
    }

    this.socket?.connect();

    this.socket?.emit('transaction:subscribe', {
      transactionId: this.config.getTransactionId(),
    });

    this.socket?.on(
      'transaction:status',
      (data: { status: string; redirectUrl: string; error: string }) => {
        this.logger.log(`Transaction status:' ${data}`);

        if (data.error === FunidAuthError.EmailAlreadyInUse) {
          this.logger.log(
            `do nothing on ${FunidAuthError.EmailAlreadyInUse} now`
          );
          return;
        }

        if (data.error) {
          this.dispatchEvent(
            new CustomEvent(FunidAuthEvent.Error, {
              detail: data.error,
              bubbles: true,
              composed: true,
            })
          );

          this.logger.log(`"${FunidAuthEvent.Error}" custom event dispatched`);
        }

        if (data.status === FunidAuthError.Fail) {
          this.logger.log(`do nothing on ${FunidAuthError.Fail} now`);
          return;
        }

        this.dispatchEvent(
          new CustomEvent(FunidAuthEvent.Success, {
            bubbles: true,
            composed: true,
          })
        );

        this.logger.log(`"${FunidAuthEvent.Success}" custom event dispatched`);
      }
    );

    this.logger.log('WS connected');
  }

  private unSubscribeWS(): void {
    if (this.socket?.connected) {
      this.socket?.removeAllListeners();
      this.socket?.disconnect();
      this.logger.log('unSubscribeWS');
    }
  }
}
