import { filter } from "rxjs/operators";

const directiveName = "getFooterDataToScope";

import "./../service/domain/http.js";
import "./../service/rx/system$.js";
import "./../service/captcha.js";

class FooterManagement {
  static $inject = ["http", "system$", "captcha"];

  constructor(http, system$, captcha) {
    this.http = http;
    this.system$ = system$;
    this.captcha = captcha;
  }

  getDataToScope(scope) {
    this.http
      .getData("footer")
      .then(({ result }) => {
        scope.text = result.text;
        scope.hiddenText = result.hiddenText;
        scope.iconCollection = result.icons;
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  link(scope) {
    this.getDataToScope(scope);

    let recaptchaToken = null;
    let needVerification = true;

    scope.validateUser = ()=> {
      if(needVerification){
        needVerification = false;
        this.captcha
          .execute()
          .then((response) => {
            recaptchaToken = response;
          })
          .catch((error) => error)
          .finally((data) => {

            if(recaptchaToken){
              this.http.postData('footerVerify', {}, recaptchaToken, true)
                .then(()=>{
                  scope.isVerified = true;
                });
            }
          })
        }
    }

    this.subscription =
      this.system$
        .pipe(filter((message) => message.action === "statusChanged"))
        .subscribe(() => {
          this.getDataToScope(scope);
        });

    this.$onDestroy = () => {
      this.subscription.unsubscribe();
    };
  }
}

app.directive(directiveName, () => {
  return {
    restrict: "A",
    controller: FooterManagement,
    link: function(scope, element, attrs, ctrl) {
      ctrl.link(scope);
    }
  };
});
