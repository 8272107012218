
































module.exports = {
  "ar": () => import("../../../../air-datepicker/locale/ar.js"),
  "bg": () => import("../../../../air-datepicker/locale/bg.js"),
  "ca": () => import("../../../../air-datepicker/locale/ca.js"),
  "cs": () => import("../../../../air-datepicker/locale/cs.js"),
  "da": () => import("../../../../air-datepicker/locale/da.js"),
  "de": () => import("../../../../air-datepicker/locale/de.js"),
  "el": () => import("../../../../air-datepicker/locale/el.js"),
  "en": () => import("../../../../air-datepicker/locale/en.js"),
  "es": () => import("../../../../air-datepicker/locale/es.js"),
  "eu": () => import("../../../../air-datepicker/locale/eu.js"),
  "fi": () => import("../../../../air-datepicker/locale/fi.js"),
  "fr": () => import("../../../../air-datepicker/locale/fr.js"),
  "hr": () => import("../../../../air-datepicker/locale/hr.js"),
  "hu": () => import("../../../../air-datepicker/locale/hu.js"),
  "id": () => import("../../../../air-datepicker/locale/id.js"),
  "it": () => import("../../../../air-datepicker/locale/it.js"),
  "ja": () => import("../../../../air-datepicker/locale/ja.js"),
  "ko": () => import("../../../../air-datepicker/locale/ko.js"),
  "nb": () => import("../../../../air-datepicker/locale/nb.js"),
  "nl": () => import("../../../../air-datepicker/locale/nl.js"),
  "pl": () => import("../../../../air-datepicker/locale/pl.js"),
  "pt-BR": () => import("../../../../air-datepicker/locale/pt-BR.js"),
  "pt": () => import("../../../../air-datepicker/locale/pt.js"),
  "ro": () => import("../../../../air-datepicker/locale/ro.js"),
  "ru": () => import("../../../../air-datepicker/locale/ru.js"),
  "si": () => import("../../../../air-datepicker/locale/si.js"),
  "sk": () => import("../../../../air-datepicker/locale/sk.js"),
  "sl": () => import("../../../../air-datepicker/locale/sl.js"),
  "sv": () => import("../../../../air-datepicker/locale/sv.js"),
  "th": () => import("../../../../air-datepicker/locale/th.js"),
  "tr": () => import("../../../../air-datepicker/locale/tr.js"),
  "uk": () => import("../../../../air-datepicker/locale/uk.js"),
  "zh": () => import("../../../../air-datepicker/locale/zh.js")
}