(function () {
  'use strict';

  app.factory('altenar.config', controller);

  controller.$inject = [];

  function controller() {
    return Object.assign(
      {},
      {
        widget: {},
        version: '3',
      },
      $_ALTENARCONFIG,
    );
  }
})();
