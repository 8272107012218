import '../service/domain/system.js';
import { GoogleCaptcha } from '../classes/captcha/google-captcha.js';
import { CloudflareCaptcha } from '../classes/captcha/cloudflare-captcha.js';

const serviceName = 'captcha';

class Captcha {
  static $inject = ['system'];

  constructor(system) {
    this.system = system;
  }

  defaultCaptcha = 'cloudflare';
  captchaFallbackToken = 'fallbackToken';

  init() {
    this.system.getSystemInfo().then((info) => {
      if (!info.captcha_strategies || info.captcha_strategies.length === 0) {
        this.type = 'google';
        return this.create('google');
      }
      if (info.captcha_strategies.length > 1) {
        this.type = this.defaultCaptcha;
        return this.create(this.defaultCaptcha);
      }
      this.type = info.captcha_strategies[0];
      return this.create(info.captcha_strategies[0]);
    });
  }

  create(captcha) {
    if (captcha === 'cloudflare') {
      this.captcha = new CloudflareCaptcha();
    }
    if (captcha === 'google') {
      this.captcha = new GoogleCaptcha();
    }
    this.captcha.init();
  }

  sendFallbackToken() {
    return new Promise((resolve, reject) => {
      if(this.type === 'google') {
        return resolve({ 'g-captcha-token': this.captchaFallbackToken });
      }
      if(this.type === 'cloudflare') {
        return resolve({ 'captcha-token': this.captchaFallbackToken });
      }
    });
  }

  execute() {
    if (this.captcha) {
      return this.captcha.execute();
    }
    return this.sendFallbackToken();
  }
}

app.service(serviceName, Captcha);
