import '../../service/domain/game.js';
import '../../service/domain/state.js';
import '../../service/rx/timer$.js';
import '../../service/rx/message$.js';
import '../../service/domain/popup.js';
import '../../service/utils/jurisdictions.js';
import '../../service/rx/ws$.js';
import '../../service/domain/modal.js';
import '../../service/configs/ws-events.js';
import '../../service/rx/post-message$.js';
import '../../service/domain/megaGameEvents.js';
import { merge, fromEvent, Subject } from 'rxjs';
import { takeUntil, filter, first, bufferCount } from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaGameField' };

  controller.$inject = [
    '$scope',
    'game',
    '$timeout',
    'state',
    'timer$',
    'message$',
    'popup',
    '$window',
    'jurisdictions',
    'ws$',
    'modal',
    'wsEvents',
    'postMessage$',
    'megaGameEvents'
  ];

  function controller(
    $scope,
    _game,
    $timeout,
    _state,
    _timer$,
    _message$,
    _popup,
    $window,
    _jurisdictions,
    _ws$,
    _modal,
    _wsEvents,
    _postMessage$,
    _megaGameEvents
  ) {
    $scope.preloader = true;
    $scope.url = '';
    const destroy$ = new Subject();
    const modalNoSimultaneousPlayChoice = 'no-simultaneous-play-choice';
    const modalNoSimultaneousPlayClosedSession = 'no-simultaneous-play-closed-session';
    const modalNoSimultaneousPlayNewSession = 'no-simultaneous-play-new-session';

    let session_id = null;
    let request = false;
    let code = null;
    let mode = null;

    const onMessage = (event) => {
      if (event.data && event.data.command == 'com.egt-bg.exit') {
        _state.goto('home');
      }
    };

    const launch = () => {
      if (this.game.alias && this.game.alias.length && this.mode.length) {
        $scope.preloader = true;

        mode = this.mode;
        code = this.game.alias;

        _game
          .launch({ code, mode })
          .then((a) => {
            $scope.url = a.result.url;
            session_id = a.result.session_id;
          })
          .catch((e) => {
            if (e.messages[0].code === 2805 || e.messages[0].code === 5070) {
              _state.goto('initialLimitPage');
            }

            if (e.messages[0].code === 2210) {
              handleSimultaneousGame(e.messages[0].data.game_session_id);
            }
          })
          .finally(() => {
            $timeout(() => {
              $scope.preloader = false;
            }, 2000);
          });
      }
    };

    const startNewSession = (launchedGameSessionId) => {
      _game.closeGameSession({ game_session_id: launchedGameSessionId }).then(launch);
    };

    const continueLaunchedSession = () => {
      _modal.open(modalNoSimultaneousPlayNewSession).then(() => {});
    };

    const handleSimultaneousGame = (launchedGameSessionId) => {
      _modal
        .open(modalNoSimultaneousPlayChoice)
        .then(() => startNewSession(launchedGameSessionId))
        .catch(() => continueLaunchedSession());
    };

    this.$onInit = () => {
      $window.addEventListener('message', onMessage, false);
    };

    this.$onChanges = launch;

    _postMessage$
      .pipe(
        takeUntil(destroy$)
      )
      .subscribe((data) => {
        if(data.message === 'callback' && data.params?.gameAction) {
          _state.goto(data.params.gameAction);
          return;
        }
        if(data.initiator === 'mega'){
          _megaGameEvents.handleEvent(data);
        }
      });

    this.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
      if (mode === 'real') {
        _popup.open({ name: 'game-session' });
      }

      $window.removeEventListener('message', onMessage, false);
    };

    _jurisdictions.de(() => {

      merge(destroy$, fromEvent(window, 'beforeunload'))
        .pipe(first())
        .subscribe(() => {
          if (session_id) {
            _game.closeGameSession({ game_session_id: session_id });
          }
        });

      _ws$
        .pipe(
          filter(({ event }) => event === _wsEvents.closeGameSession),
          takeUntil(destroy$)
        )
        .subscribe(({ data }) => {
          const sessionIdToClose = data[0].game_session_id;

          if (sessionIdToClose === session_id) {
            $timeout(() => {
              $scope.url = null;

              _modal
                .open(modalNoSimultaneousPlayClosedSession)
                .then(() => {})
                .catch(() => {});
            });
          }
        });
    });

    _jurisdictions.sw(() => {
      _timer$
        .pipe(
          bufferCount(10),
          takeUntil(destroy$)
        )
        .subscribe(() => {
          if (mode === 'real' && typeof session_id !== null && code !== null && !request) {
            request = true;
            _game
              .check({ session_id, code })
              .catch(({ messages }) => {
                if (messages[0].code === 2806) {
                  _state.goto('home');
                }
              })
              .finally(() => (request = false));
          }
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      game: '<',
      mode: '<',
    },
  });
})();
