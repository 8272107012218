import {
  SlQrCode
} from "./chunk.KB5KV6GN.js";

// src/components/qr-code/qr-code.ts
var qr_code_default = SlQrCode;
SlQrCode.define("sl-qr-code");

export {
  qr_code_default
};
