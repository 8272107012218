import '../../service/configs/config.js';
(function () {
  'use strict';

  app.factory('trustly.config', controller);

  controller.$inject = ['config'];

  function controller(_config) {
    const config =
      typeof _config.paynplay === 'object'
        ? _config.paynplay
        : {
            curacao: {
              fi: {
                enabled: true,
                min: 20,
                max: 10000,
                default: 100,
                range: [50, 100, 250],
                currency: 'EUR',
                locale: 'fi_FI',
              },
            },
            malta: {
              fi: {
                enabled: true,
                min: 20,
                max: 10000,
                default: 100,
                range: [50, 100, 250],
                currency: 'EUR',
                locale: 'fi_FI',
              },
            },
            sweden: {
              enabled: true,
              min: 100,
              max: 50000,
              default: 500,
              range: [1000, 2000, 5000],
              currency: 'SEK',
              locale: 'sv_SE',
            },
          };

    let result;

    switch (_config.jurisdiction) {
      case 'sweden':
        result = config.sweden;
        break;

      case 'curacao':
      case 'malta':
        result = config[_config.jurisdiction]?.[_config.currentLang] || { enabled: false };
        break;

      case 'germany':
        result = config.germany ? config.germany[_config.currentLang] : { enabled: false };
        break;

      default:
        result = { enabled: false };
        break;
    }

    return result;
  }
})();
