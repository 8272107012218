import './transcludeResolverEvents.js';

export const transcludeResolver = 'transcludeResolver';

class Resolver {
  static $inject = ['transcludeResolverEvents'];

  constructor(transcludeResolverEvents) {
    this.transcludeResolverEvents = transcludeResolverEvents;
  }

  get(config) {
    return this.transcludeResolverEvents.checkOnExist(config);
  }
}

app.service(transcludeResolver, Resolver);
