import config from "@b2c/core/app/service/domain/page.js";

(function(){

    'use strict';
    const directive = {name: 'socialLinks'};
    controller.$inject = ['page', '$sce'];

    function controller( _page, $sce ) {

        function link(scope, element) {
            scope.socials = '';

            _page
                .item( { name: 'social-network-list' } )
                .then( answer => {
                    scope.socials = $sce.trustAsHtml( answer.result.content );
                })
                .catch(() => {})
                .finally(() => {});
        }

        return {
            restrict: 'A',
            link:link
        };
    }

    app.directive( directive.name, controller );

})();