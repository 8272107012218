import '../v3/tournaments/extendedTournament.js';
import '../../service/domain/state.js';

(function () {
  'use strict';
  controller.$inject = ['$q', 'extendedTournament', 'state'];

  function controller($q, extendedTournament, state) {
    const defer = $q.defer();

    function isValidRoute(tournament) {
      return tournament ? defer.resolve() : defer.reject(state.goto('notFound'));
    }

    function collection(params) {
      extendedTournament
        .getItem({ alias: params.name }, { tournament_event_guid: params.guid })
        .then((item) => {
          isValidRoute(item.result);
        })
        .catch((e) => {
          state.goto('notFound');
        });
    }

    return { collection };
  }

  app.service('tournamentResolver', controller);
})();
