(function () {
  'use strict';

  let $_CONFIG = window.$_CONFIG || {};

  const languages = window.$_CONFIG.language;
  const apiVersion = window.$_CONFIG.apiVersion || 'v2';
  const storageLang = localStorage.getItem('lastLang');

  const addressLang = window.location.pathname.split('/')[1];
  if (addressLang.length === 2 && isAvailableLanguage(addressLang)) {
    setLangToStorage(addressLang);
    $_CONFIG.currentLang = addressLang;
    return;
  }

  if (isAvailableLanguage(storageLang)) {
    goTo(storageLang);
    return;
  }

  const browserLang = navigator.language.split('-')[0];
  if (isAvailableLanguage(browserLang)) {
    goTo(browserLang);
    return;
  }

  const fetchSysData = () => {
    let result;
    const request = new XMLHttpRequest();
    request.open('GET', `//${window.location.host}/en/api/${apiVersion}/system/info`, false); // `false` makes the request synchronous
    request.send(null);
    if (request.status === 200) {
      result = JSON.parse(request.response).result;
    }
    return result?.country?.code.toLowerCase();
  };

  const ipLang = fetchSysData();
  if (isAvailableLanguage(ipLang)) {
    goTo(ipLang);
    return;
  }

  const defaultLang = window.$_DRAGON.language[0];
  goTo(defaultLang);

  function setLangToStorage(lang) {
    if (storageLang !== lang) {
      localStorage.setItem('lastLang', lang);
    }
  }

  function getPathName() {
    let path = window.location.pathname.split('/');
    return addressLang.length === 2 && isAvailableLanguage(addressLang)
      ? path.slice(2).join('/')
      : path.slice(1).join('/');
  }

  function goTo(path) {
    setLangToStorage(path);
    window.location = path + '/' + getPathName() + window.location.search + window.location.hash;
  }

  function isAvailableLanguage(requestedLang) {
    return languages.includes(requestedLang) ? requestedLang : null;
  }
})();
