const _temp0 = require("./directive/add-focus- ‎on-click.js");
const _temp1 = require("./directive/get-social-links.js");
const _temp2 = require("./directive/quick-deposit-mouse-action.js");
const _temp3 = require("./directive/rabona-copy-to-clipboard.js");
const _temp4 = require("./directive/rabona-linebreaks.js");
const _temp5 = require("./directive/rabona-registration-promocode.js");
const _temp6 = require("./directive/rabona-scroll-to.js");
const _temp7 = require("./directive/rabona-split-phone.js");
const _temp8 = require("./directive/rabona-track-phone-error.js");
const _temp9 = require("./directive/scroll-when-hold-with-rtl.js");
const _temp10 = require("./directive/slick-rtl.js");
const _temp11 = require("./directive/social-links.js");
const _temp12 = require("./directive/animation/bnr-animation.js");
const _temp13 = require("./directive/animation/menu-animation.js");
const _temp14 = require("./directive/animation/promo-animation.js");

module.exports = {
  "add-focus- ‎on-click.js": _temp0,
  "get-social-links.js": _temp1,
  "quick-deposit-mouse-action.js": _temp2,
  "rabona-copy-to-clipboard.js": _temp3,
  "rabona-linebreaks.js": _temp4,
  "rabona-registration-promocode.js": _temp5,
  "rabona-scroll-to.js": _temp6,
  "rabona-split-phone.js": _temp7,
  "rabona-track-phone-error.js": _temp8,
  "scroll-when-hold-with-rtl.js": _temp9,
  "slick-rtl.js": _temp10,
  "social-links.js": _temp11,
  "animation":   {
    "bnr-animation.js": _temp12,
    "menu-animation.js": _temp13,
    "promo-animation.js": _temp14
  }
}