export class CloudflareCaptcha {
  constructor() {
    if (!$_CDN.cfRecaptcha?.key) {
      throw new Error("CloudflareCaptcha key is required.");
    }
    if (!$_CDN.cfRecaptcha?.sdk) {
      throw new Error("CloudflareCaptcha sdk is required.");
    }
    this.key = $_CDN.cfRecaptcha?.key;
    this.url = $_CDN.cfRecaptcha?.sdk;
  }

  elementId = 'cf-captcha';

  addTurnstileElement() {
    const container = document.createElement('div');
    container.id = this.elementId;
    container.style.position = 'absolute';
    container.style.left = '-9999px';
    container.style.display = 'none';
    document.body.append(container);
  }

  addScriptInDom(url) {
    const scriptRecaptcha = document.createElement('script');
    scriptRecaptcha.src = url;
    document.head.appendChild(scriptRecaptcha);

    this.addTurnstileElement();
  }

  init() {
    this.addScriptInDom(this.url);
  }

  execute() {
    if (window.turnstile) {
      return new Promise((resolve) => {
        turnstile.execute(`#${this.elementId}`, {
          sitekey: `${this.key}`,
          appearance: 'always',
          action: 'submit',
          callback: (token) => resolve({ 'captcha-response': token }),
        });
      });
    }
  }
}
