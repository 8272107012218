import '../../service/domain/http.js';

const otpVerificationApi = 'otpVerificationApi';

class Api {
  static $inject = ['http'];
  constructor(http) {
    this.http = http;
  }
  sendSms(data) {
    return this.http.postData('otpSmsVerify', null, data);
  }
  sendCode(data) {
    return this.http.postData('otpVerifyCode', null, data);
  }
}

app.service(otpVerificationApi, Api);
